<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1200">
      <v-card class="dialog-form">
        <v-card-title>View Edit Log</v-card-title>
        <v-btn class="close-button" @click="dialog = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#1D1B20"/>
          </svg>
        </v-btn>
        <ItemStockOutHistory :item="data" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiManagement } from '@/services/apis/apiManagement'
import ItemStockOutHistory from "@/components/ItemStockOutHistory.vue";
export default {
  name: 'HistoryItemStockOut',
  components: {ItemStockOutHistory},
  data: () => ({
    dialog: false,
    data: {}
  }),

  props: {
    actionShowModal: { type: Number, default: 0 },
    itemEdit: { type: Object, default: null }
  },

  watch: {
    async actionShowModal() {
      this.dialog = true
      this.getHistory()
    }
  },

  computed: {

  },

  methods: {
    async getHistory() {
      if (this.itemEdit) {
        try {
          const response = await apiManagement.getStockOutHistory(this.itemEdit.id)
          this.data = response.data
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.dialog-form {
  padding: 34px 31px;
}
.v-card__title {
  padding: 0!important;
  margin-bottom: 32px;
}
.close-button {
  position: absolute;
  top: 26px;
  right: 32px;
  background-color: transparent!important;
  border: none;
  box-shadow: none;
  padding: 0!important;
  height: unset!important;
  min-width: unset!important;
  border-radius: 0;
}
::v-deep .v-input__prepend-outer {
  display: none!important;
}
.v-card__actions {
  justify-content: flex-end;
  padding-bottom: 0;
}
</style>
