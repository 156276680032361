














































import StockOutDataHistoryTable from '@/components/inventory/StockOutDataHistoryTable.vue';
import { Vue } from 'vue-property-decorator';
import CreateStockOut from "@/components/inventory/modals/CreateStockOut.vue";
import { apiManagement } from '@/services/apis/apiManagement';
import HistoryItemStockOut from "@/components/inventory/modals/HistoryItemStockOut.vue";

export default Vue.extend({
  components: { CreateStockOut, StockOutDataHistoryTable, HistoryItemStockOut},
  data() {
    return {
      actionGetList: 0,
      searchQuery: '',
      rangeStockInTime: {
        start: null,
        end: null
      },
      actionShowModalCreate: 0,
      actionShowModalDetail: 0,
      allSkuOptions: [],
      itemEdit: null,
      inventoryFilterIds: []
    };
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.getListSkuOption()
    },

    handleBackStockOut() {
      this.$router.push('/app/inventory-stock-out')
    },
    async getListSkuOption() {
      try {
        const response = await apiManagement.getAllSkuOptions()
        this.allSkuOptions = response.data
      } catch (e) {
        console.log(e)
      }
    },
    handleExport() {
      console.log('Export');
    },
    handleAddNew() {
      this.itemEdit = null
      this.actionShowModalCreate ++
    },
    handleShowEdit(item) {
      this.itemEdit = item
      this.actionShowModalCreate ++
    },
    handleShowDetail(item) {
      this.itemEdit = item
      this.actionShowModalDetail ++
    },
    handleEnterSearch() {
      this.actionGetList++
    },
    updateFilterStockinTime(value) {
      this.rangeStockInTime = {...value}
      this.actionGetList++
    },
    changeFilterSKU() {
      this.actionGetList++
    }
  },
});
