<template>
  <div class="data-log">
    <span v-if="data.StockOutTime">Stock Out time: {{ formatDateFromBE(data.StockOutTime) }}</span>
    <span v-if="data.QuantityOut">Quantity Out: {{ data.QuantityOut }}</span>
    <span v-if="data.reason">Reason: {{ data.reason }}</span>
    <span v-if="data.description">Description: {{ data.description }}</span>
  </div>
</template>

<script>
import { formatDateFromBE } from '@/utils/format'

export default {
  methods: { formatDateFromBE },
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.data-log {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #626262;
  width: 350px;
}
</style>
