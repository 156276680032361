var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 custom-data-table",attrs:{"headers":_vm.headers,"footer-props":{'items-per-page-options':[10, 20, 50, 100, 200, 500]},"items-per-page":_vm.queryData.limit,"items":_vm.listData.data,"server-items-length":_vm.listData.count,"sort-by":"stockOutTime"},on:{"update:sort-by":_vm.onSortByChange,"update:sort-desc":_vm.onSortDescChange,"update:page":_vm.changePage,"update:items-per-page":_vm.changePerPage},scopedSlots:_vm._u([{key:"item.stockOutTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromBE(item.stockOutTime))+" ")]}},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.unitPrice))+" ")]}},{key:"item.totalValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.totalValue))+" ")]}},{key:"item.createdTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromBE(item.createdTime))+" ")]}},{key:"item.priceOut",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.priceOut))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-action"},[_c('p',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit")]),_c('p',{on:{"click":function($event){return _vm.viewDetail(item)}}},[_vm._v("Log")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }