<template>
  <div>
    <v-data-table
        :headers="headers"
        :footer-props="{'items-per-page-options':[10, 20, 50, 100, 200, 500]}"
        :items-per-page="queryData.limit"
        :items="listData.data"
        :server-items-length="listData.count"
        class="elevation-1 custom-data-table"
        @update:sort-by="onSortByChange"
        @update:sort-desc="onSortDescChange"
        @update:page="changePage"
        @update:items-per-page="changePerPage"
        sort-by="stockOutTime"
    >

      <template v-slot:item.stockOutTime="{ item }">
        {{ formatDateFromBE(item.stockOutTime) }}
      </template>
      <template v-slot:item.unitPrice="{ item }">
        {{ formatPrice(item.unitPrice) }}
      </template>
      <template v-slot:item.totalValue="{ item }">
        {{ formatPrice(item.totalValue) }}
      </template>
      <template v-slot:item.createdTime="{ item }">
        {{ formatDateFromBE(item.createdTime) }}
      </template>
      <template v-slot:item.priceOut="{ item }">
        {{ formatPrice(item.priceOut) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="table-action">
          <p @click="editItem(item)">Edit</p>
          <p @click="viewDetail(item)">Log</p>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { apiManagement } from '@/services/apis/apiManagement'
import { convertToTimestamp, formatDateFromBE, formatPrice } from '@/utils/format'
import { debounce } from 'lodash'

export default {
  data: () => ({
    headers: [
      { text: 'Stock out time', value: 'stockOutTime' },
      { text: 'SKU', sortable: false, value: 'sku' },
      { text: 'Q.TY OUT', value: 'quantityOut', align: 'end' },
      { text: 'Unit', value: 'unit' },
      { text: 'Price out', value: 'priceOut', align: 'end' },
      { text: 'DATA TYPE', value: 'type' },
      { text: 'REASON', value: 'reason' },
      { text: 'Description', value: 'description' },
      { text: 'Action', value: 'actions', sortable: false },
    ],
    listData: {
      data: [],
      count: 0,
    },
    queryData: {
      offset: 0,
      limit: 50,
      orderBy: 'stockOutTime',
      orderType: 'DESC',
      search: '',
      'filters[stockOutTime][start]': null,
      'filters[stockOutTime][end]': null,
    },
    currentPage: 1
  }),

  props: {
    searchQuery: {
      type: String,
    },
    actionGetList: {
      type: Number
    },
    rangeStockInTime: {
      type: Object
    },
    inventoryFilterIds: {
      type: Array
    }
  },

  watch: {
    actionGetList() {
      this.queryData.search = this.searchQuery
      this.debouncedFetchData()
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    formatPrice,
    formatDateFromBE,
    debouncedFetchData: debounce(function() {
      this.getList();
    }, 300),

    initialize() {
      this.debouncedFetchData()
    },

    async getList() {
      this.queryData['filters[stockOutTime][start]'] = this.rangeStockInTime.start ? convertToTimestamp(this.rangeStockInTime.start, 'DD/MM/YYYY') : null
      this.queryData['filters[stockOutTime][end]'] = this.rangeStockInTime.end ? convertToTimestamp(this.rangeStockInTime.end, 'DD/MM/YYYY') : null

      const params = {...this.queryData}
      this.inventoryFilterIds.forEach((id, index) => {
        params[`filters[inventoryId][${index}]`] = id
      })

      try {
        const response = await apiManagement.getListStockOutHistory(params)
        this.listData = {
          data: response.data.data,
          count: response.data.count
        }
      } catch (e) {
        console.log(e)
      }
    },

    changePage(value) {
      this.currentPage = value
      this.queryData.offset = this.queryData.limit * (value - 1)
      this.debouncedFetchData()
    },

    changePerPage(value) {
      this.queryData.limit = value
      this.debouncedFetchData()
    },

    onSortByChange(value) {
      this.queryData.orderBy = value || 'stockOutTime'
      this.debouncedFetchData()
    },

    onSortDescChange(value) {
      this.queryData.orderType = value === false ? 'ASC' : 'DESC'
      this.debouncedFetchData()
    },

    editItem(item) {
      this.$emit('showEdit', item)
    },

    viewDetail(item) {
      this.$emit('showDetail', item)
    }
  },
}
</script>

<style scoped lang="scss">
.custom-data-table {
  .data-table-top {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
.table-action {
  display: flex;
  align-items: end;
  justify-content: start;
  height: 100%;
  gap: 28px;
}
.table-action p {
  cursor: pointer;
  color: #0B44D8;
}
</style>
